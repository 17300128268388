import './App.css';
import logo from "./images/quickdownload.png";
import { Button, Input } from 'rsuite';
import 'rsuite/dist/rsuite.min.css';
import axios from 'axios';
import React, { useState, useEffect, useRef } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalCloseButton, ModalFooter } from '@chakra-ui/react';
import { ChakraProvider } from "@chakra-ui/react"
import QRCode from 'qrcode.react';
import BG_KHQR from "./images/bg-khqr.png";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { BakongKHQR, khqrData, IndividualInfo } from "bakong-khqr";
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import PrivacyPolicy from './PrivacyPolicy';

const subscriptions = {
  "ONE_MONTH": {
    name: "1 Month Subscription",
    price: 5.00,
  },
  "THREE_MONTHS": {
    name: "3 Months Subscription",
    price: 15.00
  }
};

function App() {
  const [showQR, setShowQR] = useState(false);
  const [showInput, setShowInput] = useState(true);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(null);
  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const wsRef = useRef(null);
  const [KHQRStringtest, setKHQRStringtest] = useState('');
  const [key, setKey] = useState("");
  const [md5, setMd5] = useState("");
  const [telegram, setTelegram] = useState("");
  const [info, setInfo] = useState(null);
  const [isOpen, setIsOpen] = useState(false);
  const [khqrSelected, setKhqrSelected] = useState(false);
  const [oldKey, setOldKey] = useState("");
  const [newKey, setNewKey] = useState("");
  const [display, setDisplay] = useState("");
  const proxyServerUrl = 'https://apifarmreel.mmoshop.me';
  const [paymentSuccess, setPaymentSuccess] = useState(false);
  const listSoftwareRef = useRef(null);
  const newPageComponentRef = useRef(null);
  const disableStripe = true;

  const image = 'https://images.pexels.com/photos/3709434/pexels-photo-3709434.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
  const image2 = 'https://images.pexels.com/photos/2387819/pexels-photo-2387819.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'

  const validateEmail = (email) => {
    return email.includes("@");
  };

  const handleEmailChange = (value) => {
    setEmail(value);
    setIsEmailValid(validateEmail(value));
  };

  const handleNextClick = () => {
    if (!isEmailValid) {
      alert('The email is not valid.');
      return;
    } else {
      setShowInput(false);
    }

    console.log('Next button clicked with valid email:', email);
    if (selectedPaymentMethod === 'KHQR') {
      setShowQR(true);
      setKhqrSelected(true);
    } else if (selectedPaymentMethod === 'VISA') {
      const amountInCents = info?.price;
      fetch(`https://apifarmreel.mmoshop.me/api/create-stripe-session?amount=${amountInCents}&email=${email}`, {
        method: 'POST',
      }).then(response => response.json()).then(data => {
        window.location.href = `${data.sessionURL}`;
      }).catch(error => console.error('Error:', error));
    }
  };

  const onOpen = (subscription) => {
    setInfo(subscription);
    setIsOpen(true);
  };

  const onClose = () => {
    setIsOpen(false);
    if (wsRef.current) {
      wsRef.current.send("ModalClosed");
      wsRef.current.close();
      wsRef.current.onclose = () => { };
    }
  };

  useEffect(() => {
    if (khqrSelected) {
      setKey('');
      const { BakongKHQR, khqrData } = require("bakong-khqr");
      const optionalData = {
        currency: khqrData.currency.usd,
        amount: info?.price,
        mobileNumber: "85510888461",
        storeLabel: "Quick Download",
        terminalLabel: "MMOSHOP_Invoices",
        purposeOfTransaction: "Quick Download",
        languagePreference: "km",
        merchantNameAlternateLanguage: "Heang",
        merchantCityAlternateLanguage: "Lyhour",
        upiMerchantAccount: "",
      };

      const individualInfo = new IndividualInfo(
        "heang_lyhour@aclb",
        "Quick Download",
        "PHNOM PENH",
        optionalData
      );

      const khqr = new BakongKHQR();
      const response = khqr.generateIndividual(individualInfo);
      const KHQRString = JSON.stringify(response.data.qr);
      const KHQR_md5 = JSON.stringify(response.data.md5);
      const KHQRString1 = KHQRString.replace(/^"(.*)"$/, "$1");
      setKHQRStringtest(KHQRString1);
      setMd5(KHQR_md5)

      wsRef.current = new WebSocket(`wss://apifarmreel.mmoshop.me/payment_quick_download?md5=${KHQR_md5}&email=${email}`)

      
      // wsRef.current = new WebSocket(`ws://localhost:7000/payment_quick_download?md5=${KHQR_md5}&email=${email}`)

      wsRef.current.onmessage = (event) => {
        if (event.data === "verified") {
          onClose();
        } else if (event.data === "closeModal") {
          onClose();
          setKhqrSelected(false);
          setIsModalOpen(false);
        } else if (event.data.includes("Buykey:")) {
          var check = event.data.split("Buykey:")[1].trim()
          check = check.replace(/'/g, '"');

          try {
            var keyObject = JSON.parse(check);
            var key = keyObject.buykey;
            var get_telegram = keyObject.telegram;
            wsRef.current.close();
            setTelegram(get_telegram);
            setKey(key);
          } catch (error) {
            console.error("", error);
          }
        }
      }
      return () => {
        if (wsRef.current) {
          wsRef.current.close()
        }
      }
    }
  }, [khqrSelected]);

  async function changeKey() {
    try {
      const response = await axios.get(`${proxyServerUrl}/farmreel/changekey?old_license=${oldKey}&new_license=${newKey}`, {
        headers: {
          'accept': 'application/json'
        }
      });
      setDisplay(response.data.detail);
    } catch (error) {
      setDisplay("Too Many Requests");
    }
  }
  const isDisabled = oldKey === "" || newKey === "";

  const scrollToBottom = (ref) => {
    const scrollY = ref.current.offsetTop;
    window.scroll({
      top: scrollY,
      behavior: 'smooth'
    });
  };

  const scrollToNewPageComponent = () => {
    newPageComponentRef.current.scrollIntoView({ behavior: 'smooth' });
  };


  function SuccessComponent() {
    const location = useLocation();

    const parseQueryParams = (query) => {
      const params = new URLSearchParams(query);
      return {
        key: params.get('key'),
        telegram: params.get('telegram')
      };
    };

    const { key, telegram } = parseQueryParams(location.search);
    console.log(key, telegram)
    return (
      <div>
        <div className="App">
          <div className='h-20 flex justify-around items-center text-center '>
            <div className="cursor-pointer">
              <img className='' src={logo} alt="" width={220} />
            </div>

            <div className='flex space-x-8 '>
              <div className="cursor-pointer" onClick={() => window.open('https://t.me/lduploadreel', '_blank')}>
                <h5 className='meun-text'> Contact</h5>
              </div>

            </div>

          </div>

          <div>
            <ChakraProvider>
              <Modal isOpen={!paymentSuccess} isCentered onClose={() => onClose()} closeOnOverlayClick={true}>
                <ModalOverlay />
                <ModalContent>
                  <ModalHeader>Thanks you for your
                    purchase!</ModalHeader>
                  <ModalCloseButton />
                  <div className="text-center mt-4 space-y-5">
                    <div className=''>
                      <p className='price-font_model'>
                        License Key : <span style={{ color: 'green' }}>{key}</span>
                      </p>
                      <p className='price-font_model space-y-20'>
                        Group Private : <span style={{ color: 'green' }}>
                          <a href={telegram} style={{ color: 'green' }} target="_blank" rel="noopener noreferrer">
                            {telegram}
                          </a>
                        </span>
                      </p>
                    </div>
                  </div>
                  <ModalFooter>
                    {/* Add any footer buttons or actions if needed */}
                  </ModalFooter>
                </ModalContent>
              </Modal>
            </ChakraProvider>
          </div>
        </div>
      </div>
    );
  }

  return (
    <Router>
      <div className="App">
        <iframe
          src='https://my.spline.design/peopleblackcrystal-705da4f5695a20f98a42d018d8e01a1e/'
          frameBorder='0'
          allow="mouse"
          style={{
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: -1,
          }}
        ></iframe>
        <Routes>
          <Route path="/success" element={<SuccessComponent />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/" element={
            <div>
              <div className="App">
                <div className='h-20 flex justify-around items-center text-center '>
                  <div className="cursor-pointer">
                    <img className='' src={logo} alt="" width={220} />
                  </div>

                  <div className='flex space-x-8 '>
                    <div className="cursor-pointer" onClick={() => window.open('https://t.me/lduploadreel', '_blank')}>
                      <h5 className='meun-text'> Contact</h5>
                    </div>
                  </div>
                </div>


                <div className='grid grid-cols-12' style={{ minHeight: "95vh", maxWidth: '140vh' }}>
                  <div className='col-span-12 lg:col-span-6 p-5 flex justify-center items-center '>
                    <div className=' space-y-5 flex-col justify-center items-center w-full'>
                      <div className='title-main flex justify-center items-center'>
                        <div className='flex justify-center items-center space-x-3 w-full' style={{ maxWidth: "60vh", overflow: "hidden" }}>
                          <div className='bg-black h-[500px] w-full rounded-2xl text-center shadow-lg hidden lg:block' style={{ backgroundColor: "#575757a8", maxWidth: "60vh", overflow: "hidden" }}>
                            <div>
                              <img className='w-full h-[190px] rounded-2xl p-2 object-cover ' src={image} alt="" width="200px" />
                              <div className='space-y-9 price-font image-content__decor-stars' style={{
                                backgroundSize: 'cover',
                                backgroundPosition: 'right bottom',
                                backgroundRepeat: 'no-repeat',
                                backgroundImage: `url(${BG_KHQR})`,
                              }}>

                                <h3>$5 USD</h3>
                                <div className='justify-center flex items-center '>
                                  <p className='p-2 rounded-2xl box-p'>+ Downloads</p>
                                </div>

                                <div>
                                  <p>- TikTok</p>
                                  <p>- Youtube shot</p>
                                  <p>- Instagram shot</p>
                                </div>

                                <div className='p-2'>
                                  <Button className='w-full' style={{ backgroundColor: '#eeeded', color: 'black', fontSize: '12px', borderRadius: '12px' }}
                                    onMouseOver={(e) => { e.target.style.backgroundColor = '#7ABC43' }}
                                    onMouseOut={(e) => { e.target.style.backgroundColor = '#eeeded' }}
                                    onClick={() => onOpen(subscriptions["ONE_MONTH"])}
                                  >1 month subscription</Button>
                                </div>
                              </div>
                            </div>
                          </div>

                          <div className='bg-black h-[500px] w-full rounded-2xl text-center shadow-md hidden lg:block ' style={{ backgroundColor: "#575757a8", maxWidth: "60vh", overflow: "hidden" }}>
                            <img className='w-full h-[190px] rounded-2xl p-2 object-cover' src={image2} alt="" width="200px" />
                            <div className='space-y-9 price-font image-content__decor-stars' style={{
                              backgroundSize: 'cover',
                              backgroundPosition: 'right bottom',
                              backgroundRepeat: 'no-repeat',
                              backgroundImage: `url(${BG_KHQR})`,
                            }}>
                              <h3>$15USD</h3>

                              <div className='justify-center flex items-center'>
                                <p className='p-2 rounded-2xl box-p'>+ Downloads</p>
                              </div>
                              <div>
                                <p>- TikTok</p>
                                <p>- Youtube shot</p>
                                <p>- Instagram shot</p>
                              </div>

                              <div className='p-2'>
                                <Button className='w-full buy-button' style={{ backgroundColor: '#eeeded', color: 'black', fontSize: '12px', borderRadius: '12px' }}
                                  onMouseOver={(e) => { e.target.style.backgroundColor = '#7ABC43' }}
                                  onMouseOut={(e) => { e.target.style.backgroundColor = '#eeeded' }}
                                  onClick={() => onOpen(subscriptions["THREE_MONTHS"])}
                                >3 months subscription</Button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* <div className='title-main flex justify-center items-center'>
                        <div className="space-y-5 p-5 rounded-2xl text-center shadow-2xl w-full h-[200px]" style={{ backgroundImage: `url("https://mmoshop.me/static/media/background-table.55cef0dc9e019c4187c2.svg")`, backgroundColor: "#575757a8", maxWidth: "60vh", overflow: "hidden" }}>
                          <div className='flex items-center justify-center h-full'>
                            <span className='w-full space-y-5'>
                              <p className='title-text'>Change License Key</p>
                              <div className='flex space-x-4'>
                                <Input value={oldKey} placeholder='Old License' onChange={(e) => setOldKey(e)} />
                                <Input value={newKey} placeholder='New License' onChange={(e) => setNewKey(e)} />
                              </div>
                              <div className=''>
                                <Button color="green" appearance="primary" className='w-full button-color' onClick={changeKey} disabled={isDisabled}>Change Key</Button>
                              </div>

                              <p style={{ fontSize: "14px", fontWeight: "600", color: display === "Change success" ? "green" : (display === "Change limit exceeded" || display === "Too Many Requests" ? "red" : "inherit") }}>{display}</p>

                            </span>
                          </div>
                        </div>
                      </div> */}


                      <div className='title-main flex justify-center items-center'>
                        <div className="space-y-5 p-5 rounded-2xl w-full h-[500px]" style={{maxWidth: "60vh", overflow: "hidden" }}>
                          <div className='flex items-center justify-center h-full'>
                            <span className='w-full space-y-5'>
                              <div className='' style={{color:'#a5a5a5'}}>
                        <div className=" space-x-4 space-y-2">
                          <h5>Features:</h5>
                          <ul class="list-disc">
                            <li>Simple and Fast: Just paste the profile link and download all videos in one go.</li>
                            <li>High Quality: Save videos in their original resolution.</li>
                            <li>User-Friendly: Intuitive interface makes downloading videos easy for everyone.</li>
                            <li>Safe and Secure: Your privacy is our priority; we ensure your data is protected.</li>
                            <li>Supports Multiple Platforms: Works with various social media profiles including Instagram, TikTok, Facebook.</li>
                          </ul>
                        </div>

                        <div className='mt-6'></div>

                        <div className="space-x-4 space-y-2">
                          <h5>How to Use:</h5>
                          <ul class="list-decimal">
                            <li>Copy the link of the profile you want to download videos from.</li>
                            <li>Paste the link into the Profile Video Downloader app.</li>
                            <li>Click the download button and let the app do the rest.</li>
                          </ul>

                        </div>

                        <div className='mt-6'></div>

                        <div className="space-x-4 space-y-2">
                          <h5>Why Choose Profile Video Downloader?</h5>
                          <ul class="list-disc">
                            <li>Download all videos from a profile quickly and effortlessly.</li>
                            <li>No need to download videos one by one; get them all in one go.</li>
                            <li>Perfect for social media enthusiasts, content creators, and anyone who loves saving videos.</li>
                          </ul>

                        </div>
                      </div>
                            </span>
                          </div>
                        </div>
                      </div>



                      </div>
      
                  </div>
                </div>

                {isOpen &&
                  <ChakraProvider>
                    <Modal isOpen={isOpen} isCentered onClose={() => onClose()} closeOnOverlayClick={false}>
                      <ModalOverlay />
                      <ModalContent style={{ backgroundColor: "#1c1c1cf5", borderRadius: '20px', minHeight: '33vh' }}>
                        <ModalHeader>
                          <div className=''>
                            <div style={{ fontSize: '18px', fontWeight: 'bold', color: 'white' }}>
                              Payment method
                            </div>
                            <div style={{ fontSize: '14px', color: '#a5a5a5' }}>
                              Select your preferred payment method
                            </div>
                          </div>
                        </ModalHeader>

                        <ModalCloseButton color="white" />


                        <ModalBody className='payment-methods-modal justify-center'>
                          <div className="email-input-container mb-4">
                            {showInput && (
                              <div>
                                <Input
                                  placeholder="Enter your email for the license key."
                                  value={email}
                                  onChange={handleEmailChange}
                                  type="email"
                                />
                              </div>
                            )}
                          </div>
                          {!showQR && (
                            <>
                              <div className={`payment-method ${selectedPaymentMethod === 'KHQR' ? 'selected' : ''}`} onClick={() => setSelectedPaymentMethod('KHQR')}>
                                <div className="payment-logo khqr-logo"></div>
                                <div className="payment-details">
                                  <div className="card-title">Pay with KHQR</div>
                                </div>
                                <div className={`selection-indicator ${selectedPaymentMethod === 'KHQR' ? 'selected' : ''}`}></div>
                              </div>

                             {/* Conditionally render or disable the Stripe payment option */}
    {disableStripe ? (
      <div className="payment-method disabled">
        <div className="payment-logo visa-logo"></div>
        <div className="payment-details">
          <div className="card-title">Pay with Stripe (Disabled)</div>
        </div>
        <div className="selection-indicator"></div>
      </div>
    ) : (
      <div className={`payment-method ${selectedPaymentMethod === 'VISA' ? 'selected' : ''}`} onClick={() => setSelectedPaymentMethod('VISA')}>
        <div className="payment-logo visa-logo"></div>
        <div className="payment-details">
          <div className="card-title">Pay with Stripe</div>
        </div>
        <div className={`selection-indicator ${selectedPaymentMethod === 'VISA' ? 'selected' : ''}`}></div>
      </div>
    )}

                              <Button
                                color="green" appearance="primary" className='w-full button-color'
                                isDisabled={!isEmailValid}
                                onClick={handleNextClick}
                              >
                                Next
                              </Button>
                            </>
                          )}
                          <div className='flex justify-center items-center flex-col'>
                            {showQR && selectedPaymentMethod === 'KHQR' && (
                              <>
                                <div className='flex justify-center items-center'>
                                  <QRCode value={KHQRStringtest} size={200} bgColor="white" />
                                </div>
                                {key && (
                                  <div className="text-center mt-4 space-y-5">
                                    <div className=''>
                                      <p className='price-font_model'>
                                        License Key : <span style={{ color: 'green' }}>{key}</span>
                                      </p>
                                      {/* <p className='price-font_model space-y-20'>
                                        Group Private : <span style={{ color: 'green' }}>
                                          <a href={telegram} style={{ color: 'green' }} target="_blank" rel="noopener noreferrer">
                                            {telegram}
                                          </a>
                                        </span>
                                      </p> */}
                                    </div>

                                    <div className="space-y-20">
                                      <CopyToClipboard text={key}>
                                        <Button className='w-full' color="green" appearance="primary">Copy Key</Button>
                                      </CopyToClipboard>
                                    </div>
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </ModalBody>
                        <ModalFooter>
                        </ModalFooter>
                      </ModalContent>
                    </Modal>
                  </ChakraProvider>
                }
              </div>
            </div>
          } />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
